<template>
  <div>
    <v-sheet class="mb-4">
      <v-row cols="12" class="d-flex">
        <v-col cols="12" md="8" sm="12" xs="12" :class="{
          'd-flex': true,
          'justify-start': $vuetify.breakpoint.name != 'xs',
          'justify-center': $vuetify.breakpoint.name == 'xs',
          'align-center': true,
        }">
          <v-btn outlined color="grey darken-2" class="mr-4" @click="setToday">
            Hoje
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="prev">
            <v-icon small>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="next">
            <v-icon small>mdi-chevron-right</v-icon>
          </v-btn>
          <h3 v-if="$refs.calendar">{{ $refs.calendar.title }}</h3>
        </v-col>
        <v-col :class="{
          'd-flex': true,
          'justify-end': $vuetify.breakpoint.name != 'xs',
          'justify-center': $vuetify.breakpoint.name == 'xs',
          'align-center': true,
        }" cols="12" md="4" sm="12" xs="12">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" dense icon @click="$emit('abrirModalBuscarAgendamento')">
                <v-icon dense>mdi-magnify</v-icon>
              </v-btn>
            </template>
            <span>Buscar</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" dense icon @click="$emit('abrirConfiguracao')" class="ml-2 mr-2">
                <v-icon dense>mdi-cog</v-icon>
              </v-btn>
            </template>
            <span>Configurações</span>
          </v-tooltip>

          <v-menu bottom right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn dense outlined color="grey darken-2" v-bind="attrs" v-on="on">
                <span>{{ typeToLabel[type] }}</span>
                <v-icon right> mdi-menu-down </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="type = 'day'">
                <v-list-item-title>Dia</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'week'">
                <v-list-item-title>Semana</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'month'">
                <v-list-item-title>Mês</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
    </v-sheet>
    <v-sheet class="altura">
      <v-calendar color="success" ref="calendar" locale="pt-BR" v-model="value" :short-intervals="false" :type="type"
        :style="{ '--cor-de-fundo': corDeFundo }" :events="events" :event-overlap-threshold="30"
        @click:event="showEvent" @click:more="viewDay" @change="handleCalendarChange">
        <template v-slot:day-body="{ date }">
          <div v-if="exibirDataAtual(date)" class="v-current-time first" :style="{ top: nowY }"></div>
        </template>
        <template v-slot:interval="{ time, week, minutesToPixels, date }">
          <template v-if="verificarFeriados(date)">
            <v-sheet :color="themeDark ? '#293845' : 'blue lighten-5'" width="100%" height="100%" tile></v-sheet>
          </template>
          <template v-else-if="verificarDatasBloqueadas(date)">
            <v-sheet :color="themeDark ? '#472d2a' : 'red lighten-5'" width="100%" height="100%" tile></v-sheet>
          </template>
          <template v-else>
            <template v-for="horario in getHorarios(date, week)">
              <v-sheet @click="criarAgendamento(date, time)" :key="horario.id + '1'" class="d-flex"
                :color="themeDark ? '#212121' : 'white'" width="100%" :style="{
                  'margin-top': time.substring(0, 2) == horario.horaInicio.substring(0, 2) &&
                    horario.horaFim.substring(0, 2) !== horario.horaInicio.substring(0, 2) ?
                    horario.horaInicio.substring(3, 5) == '00' ? '0' : minutesToPixels(parseInt(horario.horaInicio.substring(3, 5))) + 'px' : '0',
                }" :height="time.substring(0, 2) == horario.horaInicio.substring(0, 2) && horario.horaFim.substring(0, 2) !==
                  horario.horaInicio.substring(0, 2) ? horario.horaInicio.substring(3, 5) == '00' ?
                  '100%' : 60 - minutesToPixels(horario.horaInicio.substring(3, 5)) + 'px' : '0%'" tile>
              </v-sheet>
              <v-sheet @click="criarAgendamento(date, time)" :key="horario.id + '2'" class="d-flex"
                :color="themeDark ? '#212121' : 'white'" width="100%" :height="time.substring(0, 2) > horario.horaInicio.substring(0, 2) && time.substring(0, 2) < horario.horaFim.substring(0, 2) &&
                  horario.horaFim.substring(0, 2) !== horario.horaInicio.substring(0, 2) ? '100%' : '0%'" tile>
              </v-sheet>
              <v-sheet @click="criarAgendamento(date, time)" :key="horario.id + '3'" class="d-flex"
                :color="themeDark ? '#212121' : 'white'" width="100%" :height="time.substring(0, 2) == horario.horaFim.substring(0, 2) && horario.horaFim.substring(0, 2) !==
                  horario.horaInicio.substring(0, 2) ? horario.horaFim.substring(3, 5) == '00' ?
                  '0%' : minutesToPixels(horario.horaFim.substring(3, 5)) + 'px' : '0%'" tile>
              </v-sheet>
              <v-sheet @click="criarAgendamento(date, time)" :key="horario.id + '4'" class="d-flex"
                :color="themeDark ? '#212121' : 'white'" width="100%" :style="{
                  'margin-top': time.substring(0, 2) == horario.horaInicio.substring(0, 2) &&
                    horario.horaFim.substring(0, 2) == horario.horaInicio.substring(0, 2) ?
                    minutesToPixels(horario.horaInicio.substring(3, 5)) + 'px' : '0',
                }"
                :height="time.substring(0, 2) == horario.horaInicio.substring(0, 2) &&
                  horario.horaFim.substring(0, 2) == horario.horaInicio.substring(0, 2) ?
                  minutesToPixels(parseInt(horario.horaFim.substring(3, 5)) - parseInt(horario.horaInicio.substring(3, 5))) + 'px' : '0%'"
                tile>
              </v-sheet>
            </template>
          </template>
        </template>
        <template #day-label="{ past, date, day }">
          <div class="d-flex justify-space-between align-center" @click="setData(date, true)">
            <v-hover v-slot="{ hover }">
              <span :class="{
                'px-2 py-1 text-center d-flex justify-center align-center grey--text': past,
                'px-2 py-1 mb-2 text-center d-flex justify-center align-center white--text rounded-circle success': date == value,
                'px-2 py-1 mb-2 text-center d-flex justify-center align-center': !past && !exibirDataAtual(date),
                'px-2 py-1 mb-2 text-center d-flex justify-center align-center rounded-circle': exibirDataAtual(date),
                'text-center d-flex justify-center align-center on-hover rounded-circle grey lighten-1': hover
              }" :style="exibirDataAtual(date) ? 'border: 1px solid #2dcb73; ' : ''" :elevation="hover ? 12 : 2">{{ day
                }}
              </span>
            </v-hover>
            <div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="warning" dark v-bind="attrs" v-on="on" small class="mr-1" v-show="past">
                    mdi-calendar-remove
                  </v-icon>
                </template>
                <span>Data passada</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="error" dark v-bind="attrs" v-on="on" small class="mr-1"
                    v-show="verificarDatasBloqueadas(date)">
                    mdi-lock
                  </v-icon>
                </template>
                <span>Data bloqueada</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="blue" dark v-bind="attrs" v-on="on" small class="mr-1"
                    v-show="verificarFeriados(date)">
                    mdi-party-popper
                  </v-icon>
                </template>
                <span>Feriado</span>
              </v-tooltip>
            </div>
          </div>
        </template>
        <template #day-label-header="{ past, date, day }">
          <div class="d-flex justify-center align-center flex-column" @click="criarAgendamento(date)">
            <v-hover v-slot="{ hover }">
              <span :class="{
                'px-2 py-1 text-center d-flex justify-center align-center grey--text': past,
                'px-2 py-1 mb-2 text-center d-flex justify-center align-center white--text rounded-circle success': date == value,
                'px-2 py-1 mb-2 text-center d-flex justify-center align-center': !past && !exibirDataAtual(date),
                'px-2 py-1 mb-2 text-center d-flex justify-center align-center rounded-circle': exibirDataAtual(date),
                'on-hover rounded-circle grey lighten-1': hover,
              }" :style="exibirDataAtual(date) ? 'border: 1px solid #2dcb73; ' : ''" :elevation="hover ? 12 : 2">{{ day
                }}</span>
            </v-hover>
            <div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="warning" dark v-bind="attrs" v-on="on" small class="mr-1" v-show="past">
                    mdi-calendar-remove
                  </v-icon>
                </template>
                <span>Data passada</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="error" dark v-bind="attrs" v-on="on" small class="mr-1"
                    v-show="verificarDatasBloqueadas(date)">
                    mdi-lock
                  </v-icon>
                </template>
                <span>Data bloqueada</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="blue" dark v-bind="attrs" v-on="on" small class="mr-1"
                    v-show="verificarFeriados(date)">
                    mdi-party-popper
                  </v-icon>
                </template>
                <span>Feriado</span>
              </v-tooltip>
            </div>
          </div>
        </template>
        <template v-slot:event="{ event }">
          <v-menu open-on-hover offset-y>
            <template v-slot:activator="{ on, attrs }">
              <span class="font-weight-black" v-bind="attrs" v-on="on">
                {{ event.horaAgendamento }} - {{ event.tipoAgendamento }}
              </span>
            </template>
            <v-card class="pa-4 menu-card" color="grey lighten-3">
              <v-chip color="grey lighten-1" dark class="text-uppercase font-weight-bold mr-2">
                {{ event.tipoAgendamento }}
              </v-chip>
              <v-chip :color="obterCorSituacaoAgendamento(event.situacaoAgendamento)" dark
                class="text-uppercase font-weight-bold">
                {{ event.situacaoAgendamento === 'EmEspera'
                  ? 'Em Espera'
                  : event.situacaoAgendamento === 'EmAndamento'
                    ? 'Em Andamento'
                    : event.situacaoAgendamento
                }}
              </v-chip>
              <v-divider></v-divider>
              <v-card-text>
                <p><strong>Paciente:</strong> {{ event.name }}</p>
                <p><strong>Horário:</strong> {{ event.horaAgendamento }}</p>

                <v-chip v-if="event.agendamentoConfirmado" color="green lighten-2" dark
                  class="text-uppercase font-weight-bold">
                  Agendamento Confirmado
                </v-chip>
                <v-chip v-else color="yellow darken-1" dark class="text-uppercase font-weight-bold">
                  Agendamento Não Confirmado
                </v-chip>
              </v-card-text>
            </v-card>
          </v-menu>
        </template>
      </v-calendar>
      <v-dialog v-model="modalVisualizarAgendamento" :close-on-content-click="false" max-width="900">
        <v-card class="mx-auto">
          <v-toolbar flat outlined>
            <v-toolbar-title><v-icon color="success" left size="x-large">mdi-file-document-plus</v-icon>
              Detalhes do
              Agendamento</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="modalVisualizarAgendamento = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-container>
              <v-card-text>
                <div v-if="selectedEvent.tipoAgendamento === 'Consulta'">
                  <v-row align="center" class="d-flex justify-center mb-4">

                    <v-col cols="12" sm="4" md="3" class="text-center">
                      <v-avatar style="align-self: start;" color="success" size="9rem">
                        <img v-if="selectedEvent.paciente.foto"
                          :src="'https://medsystemcloud-new.s3.us-west-2.amazonaws.com/' + selectedEvent.paciente.foto">
                        <v-icon v-else dark size="120">mdi-account</v-icon>
                      </v-avatar>
                    </v-col>
                    <v-col cols="12" sm="4" md="5" class="text-center">
                      <span class="font-weight-bold" style="font-size: 1.3rem;" v-html="selectedEvent.name"></span>
                      <br>

                      <span class="font-weight-regular"
                        v-html="formatarTelefone(selectedEvent.paciente.contato)"></span>
                      <v-divider vertical></v-divider>

                      <v-col cols="12" sm="12" md="12" class="text-center">
                        <span class="font-weight-bold" style="font-size: 1rem;"
                          v-html="calcularIdadeDoPaciente(selectedEvent.paciente.dataNascimento)"></span>
                        <br>
                        <span class="font-weight-regular" v-html="selectedEvent.paciente.cpf"></span>
                        <br>
                        <span class="font-weight-regular">{{ getSexo(selectedEvent.paciente.sexo)
                          }}</span>

                      </v-col>
                    </v-col>

                  </v-row>

                  <v-divider></v-divider>
                  <v-row class="mt-4 d-flex justify-center">
                    <v-col cols="12" sm="12" md="12" class=" d-flex justify-center">
                      <span class="font-weight-bold mr-2" style="font-size: 1.1rem;">Agendado por:
                      </span>
                      <span class="font-weight-regular" style="font-size: 1rem;">
                        {{
                          selectedEvent.usuario.nome +
                          " " +
                          selectedEvent.usuario.sobrenome
                        }}
                      </span>
                    </v-col>
                    <v-col cols="12" sm="12" md="7" class=" d-flex justify-center">
                      <span class="font-weight-bold" style="font-size: 1.1rem;"
                        v-html="selectedEvent.dataAgendamento + ' - ' + selectedEvent.horaAgendamento + ' às ' + formatarHora(selectedEvent.end)"></span>
                    </v-col>
                  </v-row>
                  <v-chip small label class="mb-1 mt-3 d-flex justify-center" dark color="success">
                    <b v-html="selectedEvent.tipoAgendamento"></b>
                  </v-chip>
                  <v-chip small label dark class="mb-1 d-flex justify-center" :color="selectedEvent.color">
                    <b class="text-center" v-html="selectedEvent.situacaoAgendamento"></b>
                  </v-chip>
                  <v-row v-if="!selectedEvent.agendamentoConfirmado">
                    <v-col>
                      <v-alert border="left" colored-border type="warning" elevation="2" dense class="mt-2">
                        <v-row align="center">
                          <v-col class="grow">
                            Agendamento ainda não foi confirmado.
                          </v-col>
                          <v-col class="shrink">
                            <v-btn @click="confirmarAgendamento(selectedEvent.id)">
                              <v-icon left color="success">
                                mdi-whatsapp
                              </v-icon>
                              Enviar link de confirmação
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-alert>
                    </v-col>

                  </v-row>
                  <v-row v-else>
                    <v-col>
                      <v-alert border="left" colored-border type="success" elevation="2" dense class="mt-2">
                        Agendamento confirmado!
                      </v-alert>
                    </v-col>

                  </v-row>
                </div>

                <!-- COMPROMISSO VISUAL  -->
                <div v-else>
                  <v-col cols="12" sm="12" md="12" class="text-center">

                    <span class="font-weight-bold " style="font-size: 1.2rem;">Descrição: </span><b
                      style="font-size: 1rem;" class="font-weight-regular" v-html="selectedEvent.name"></b>
                  </v-col>
                  <v-row>
                    <v-col cols="12" sm="12" md="12" class="text-center mb-1">
                      <v-col cols="12" sm="12" md="12" class=" d-flex justify-center">
                        <span class="font-weight-bold" style="font-size: 1.1rem;">Agendado por:
                        </span>
                        <span class="font-weight-regular" style="font-size: 1rem;">
                          {{
                            selectedEvent.usuario.nome +
                            " " +
                            selectedEvent.usuario.sobrenome
                          }}
                        </span>
                      </v-col>
                      <span class="font-weight-bold mb-1" style="font-size: 1.1rem;"
                        v-html="selectedEvent.dataAgendamento + ' - ' + selectedEvent.horaAgendamento + ' às ' + formatarHora(selectedEvent.end)"></span>
                    </v-col>
                  </v-row>
                  <v-row>
                  </v-row>
                  <v-chip small label class="mb-1 d-flex justify-center" dark color="yellow darken-2">
                    <b v-html="selectedEvent.tipoAgendamento"></b>
                  </v-chip>
                  <v-chip small label dark class="mb-1 d-flex justify-center" :color="selectedEvent.color">
                    <b class="text-center" v-html="selectedEvent.situacaoAgendamento"></b>
                  </v-chip>
                </div>
              </v-card-text>
            </v-container>
          </v-card-text>
          <v-col cols="12" sm="12" md="12" class="text-end" style="background-color: #f7f7f7; padding: 0.5vh;">

            <v-btn @click="editarAgendamento(selectedEvent.id)" color="success" class="pr-2" dark>
              <v-icon left>mdi-pencil</v-icon>Editar Agendamento
            </v-btn>
          </v-col>
        </v-card>
      </v-dialog>
      <v-dialog v-model="modalConfirmarAgendamento" max-width="500">
        <v-card class="mx-auto">
          <v-toolbar flat outlined>
            <v-toolbar-title><v-icon color="success" left size="x-large">mdi-whatsapp</v-icon>
              Selecione o telefone do paciente
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="modalConfirmarAgendamento = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-radio-group v-model="telefoneSelecionadoParaWhatsApp">
              <v-radio v-if="selectedEvent.paciente.contato?.telefonePrincipal"
                :label="selectedEvent.paciente.contato.telefonePrincipal" value="1" color="success">
              </v-radio>
              <v-radio class="mt-2" v-if="selectedEvent.paciente.contato?.telefoneSecundario"
                :label="selectedEvent.paciente.contato.telefoneSecundario" value="2" color="success">
              </v-radio>
              <v-radio color="success" value="3">
                <template v-slot:label>
                  <soi-input class="mt-3" :disabled="telefoneSelecionadoParaWhatsApp != '3'" :name="'Outro'"
                    :label="'Outro:'" v-model="outroTelefoneParaWhatsApp" :mask="masks.telefone"
                    :placeholder="'(00) 00000-0000'"></soi-input>
                </template>
              </v-radio>
            </v-radio-group>
            <v-btn block id="salvar" type="input" color="success"
              :disabled="telefoneSelecionadoParaWhatsApp === '' || (telefoneSelecionadoParaWhatsApp === '3' && outroTelefoneParaWhatsApp == '')"
              @click="abrirWhatsAppParaConfirmarAgendamento">
              <v-icon left>mdi-whatsapp</v-icon>
              Abrir WhatsApp
            </v-btn>
          </v-card-text>

        </v-card>
      </v-dialog>
    </v-sheet>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import DateHelper from "../../helpers/DateHelper";
import Agenda from "../../models/agenda/Agenda";
import BloqueioHorarioAgenda from "../../models/agenda/BloqueioHorarioAgenda";
import FeriadoAgenda from "../../models/agenda/FeriadoAgenda";
import Agendamento from "../../models/agendamento/Agendamento";
import Calendario from "../../models/agendamento/Calendario";
import { TipoVisualizacao } from "../../models/enums/EnumTipoVisualizacao";
import { AgendamentoService } from "../../services/AgendamentoService";
import { decode, getToken } from "../../utils/Token";
import MaskHelper from "@/helpers/MaskHelper";
import { AlertaSimplesErro } from "@/helpers/MensagemHelper";
import Contato from "@/models/objetosDeValor/Contato";
export default Vue.extend({
  name: "soiCalendario",
  props: {
    agenda: {
      type: Agenda,
      required: true,
    },
    eventos: {
      type: [],
      required: true,
    },
  },
  data: () => ({
    dateHelper: DateHelper,
    telefonePrincipal: 'Telefone não informado',
    telefoneSecundario: 'Telefone não informado',
    emailPrincipal: 'E-mail não informado',
    emailSecundario: '',
    ready: false,
    type: "week",
    types: ["month", "week", "day"],
    typeToLabel: { month: "Mês", week: "Semana", day: "Dia" },
    value: new Date(Date.now()).toISOString().substr(0, 10),
    events: [],
    selectedEvent: new Calendario(),
    selectedElement: null,
    modalVisualizarAgendamento: false,
    modalConfirmarAgendamento: false,
    horarios: Array<{ text: string; value: string }>(),
    horariosAgenda: [],
    agendamentoService: new AgendamentoService(),
    agendamento: new Agendamento(),
    date: DateHelper.formataParaAnoMesDia(
      new Date(Date.now()).toISOString().substr(0, 10)
    ),
    dateFormatted: DateHelper.formataParaDiaMesAno(
      new Date(Date.now()).toISOString().substr(0, 10)
    ),
    motivoBloqueio: "",
    idAgenda: 1,
    masks: MaskHelper,
    outroTelefoneParaWhatsApp: "",
    telefoneSelecionadoParaWhatsApp: ""
  }),
  computed: {
    permiteCadastroAgendamentos() {
      const idToken = decode(getToken()).id;

      if (idToken === this.agenda.usuarioId) {
        return true;
      }
      else if (this.agenda.agendasUsuarios.find((x: { usuarioId: string; }) => x.usuarioId === idToken)) {
        return this.agenda.agendasUsuarios.find((x: { usuarioId: string; }) => x.usuarioId === idToken).permiteCadastroAgendamentos
      }
      else {
        return false;
      }
    },
    //#region <Métodos para o horário atual no calendar>
    cal() {
      return this.ready ? this.$refs.calendar : null;
    },
    nowY() {
      return this.cal ? this.cal.timeToY(this.cal.times.now) + "px" : "-10px";
    },
    //#endregion
    themeDark() {
      return this.$vuetify.theme.dark;
    },
    corDeFundo() {
      return this.$vuetify.theme.dark ? '#424242' : '#f7f7f7';
    }
  },
  watch: {
    eventos() {
      this.events = this.eventos;
    },
    "agenda.tipoVisualizacao"() {
      this.buscarModoVisualizacaoAgenda();
    },
    value() {
      this.selectedOpen = false;
      this.$emit('dia', this.value);
    },
  },
  mounted() {
    this.$nextTick(async function () {
      this.ready = true;
      this.scrollToTime();
      this.updateTime();
      this.$on("agendamento-confirmado", this.escutarAgendamentoConfirmado);
    });
  },
  methods: {
    escutarAgendamentoConfirmado(agendamentoConfirmado: boolean) {
      this.selectedEvent.agendamentoConfirmado = agendamentoConfirmado;
    },
    formatarHora(dataString: string) {
      const data = new Date(dataString);
      const hora = data.getHours().toString().padStart(2, "0");
      const minutos = data.getMinutes().toString().padStart(2, "0");
      return `${hora}:${minutos}`;
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getHorarios(date: string, week: any) {
      const diaSemana = week.find((x: { date: string; }) => x.date === date)?.weekday;
      return this.agenda.listaHorarios.filter((x: { diaSemana: any; }) => x.diaSemana === diaSemana);
    },
    abrirModalAgendamentoComHorario(tms: any) {
      const date = new Date(
        tms.year,
        tms.month - 1,
        tms.day,
        tms.hour,
        tms.minute
      );
      this.criarAgendamento(DateHelper.formatarParaAnosMesDiaISO(date.toISOString()));
    },
    verificarDataPassada(date: string) {
      if (DateHelper.dataMenorQueHoje(date)) {
        return true;
      }
    },
    exibirDataAtual(date: any) {
      if (DateHelper.retornaDataAtual(date)) {
        return true;
      }
    },
    verificarDatasBloqueadas(dataParaVerificar: string): boolean {
      var dataParaVerificarTipada: Date = new Date(dataParaVerificar + "T00:00:00");
      var bloqueio: BloqueioHorarioAgenda | undefined = undefined;

      bloqueio = this.agenda.listaBloqueios
        .find((periodo: { dataInicio: string | number | Date; dataFim: string | number | Date; }) =>
          dataParaVerificarTipada >= new Date(periodo.dataInicio)
          && dataParaVerificarTipada <= new Date(periodo.dataFim)
        );

      if (bloqueio) {
        return true;
      }

      return false;
    },
    verificarFeriados(dataParaVerificar: string): boolean {
      var feriado: FeriadoAgenda | undefined = undefined;

      feriado = this.agenda.listaFeriados
        .find((feriado: { data: string | number | Date; }) =>
          new Date(feriado.data).getDate() == new Date(dataParaVerificar + "T00:00:00").getDate()
          && new Date(feriado.data).getMonth() == new Date(dataParaVerificar + "T00:00:00").getMonth());

      if (feriado) {
        return true;
      }

      return false;
    },
    desabilitarDatas(dataParaVerificar: string): boolean {
      const data: Date = new Date(dataParaVerificar + "T00:00:00");

      if (DateHelper.dataMenorQueHoje(dataParaVerificar)) {
        return false;
      }

      const datasBloqueadas = this.datasBloqueadas
        .some((periodo: { dataInicio: string | number | Date; dataFim: string | number | Date; }) =>
          data >= new Date(periodo.dataInicio)
          && data <= new Date(periodo.dataFim));

      if (datasBloqueadas) {
        return false;
      }

      const feriados = this.feriados.some((feriado: { data: string; }) => feriado.data == dataParaVerificar + "T00:00:00");

      if (feriados) {
        return false;
      }

      return true;
    },
    criarAgendamento(data: Date, horario: string = "") {
      if (this.permiteCadastroAgendamentos) {
        this.value = data;
        this.$emit("abrirModalAgendamento", data, horario);
      }
    },
    editarAgendamento(agendamentoId: any) {
      this.modalVisualizarAgendamento = false;

      if (this.permiteCadastroAgendamentos) {
        this.$emit("editarAgendamento", agendamentoId);
      }
    },
    //#region <Função para a abertura da pré visualização do agendamento>
    showEvent({ event }) {
      this.selectedEvent = event;
      this.modalVisualizarAgendamento = true;
    },
    getSexo(sexo: number) {
      if (sexo === 0) {
        return 'Sexo Masculino';
      }
      else if (sexo === 1) {
        return 'Sexo Feminino';
      }
      else {
        return 'Sexo não informado';
      }
    },
    formatarTelefone(contato: Contato) {
      const partes = [];

      if (contato && contato.telefonePrincipal) {
        partes.push(contato.telefonePrincipal);
      }

      if (contato && contato.telefoneSecundario) {
        partes.push(contato.telefoneSecundario);
      }

      if (partes.length > 0) {
        return partes.join(' | ');
      }
      else {
        return 'Informações de contato não disponíveis';
      }
    },
    calcularIdadeDoPaciente(dataNascimento) {
      return DateHelper.calculaIdadePorExtenso(dataNascimento);
    },
    //#endregion
    //#region <Funções para a navegação do calendar>
    buscarModoVisualizacaoAgenda() {
      if (this.agenda.tipoVisualizacao === TipoVisualizacao.Mes) {
        this.type = "month";
      }
      else if (this.agenda.tipoVisualizacao === TipoVisualizacao.Semana) {
        this.type = "week";
      }
      else if (this.agenda.tipoVisualizacao === TipoVisualizacao.Dia) {
        this.type = "day";
      }
      else {
        this.type = "week";
      }
    },
    setToday() {
      this.value = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substring(0, 10);
    },
    setData(date: Date, typeDay: boolean = false) {
      this.value = date;

      if (typeDay) {
        this.type = "day";
      }
    },
    prev() {
      this.$refs.calendar.prev();
      this.$emit("buscaAgendamentos", this.value);
    },
    next() {
      this.$refs.calendar.next();
      this.$emit("buscaAgendamentos", this.value);
    },
    //#endregion
    //#region <Funções para mostrar o horário atual no calendar>
    getCurrentTime() {
      return this.cal
        ? this.cal.times.now.hour * 60 + this.cal.times.now.minute
        : 0;
    },
    scrollToTime() {
      const time = this.getCurrentTime();
      const first = Math.max(0, time - (time % 30) - 30);
      this.cal.scrollToTime(first);
    },
    updateTime() {
      setInterval(() => this.cal.updateTimes(), 60 * 1000);
    },
    handleCalendarChange() {
      this.selectedOpen = false;
    },
    //#endregion
    confirmarAgendamento(idAgendamento: number) {
      this.modalConfirmarAgendamento = true;
      // window.open(`https://api.whatsapp.com/send?phone=${'5566996519648'}&text=${'aqui o link http://google.com'}`, 'whatsappweb');
    },
    async abrirWhatsAppParaConfirmarAgendamento() {
      let telefone: string;

      switch (this.telefoneSelecionadoParaWhatsApp) {
        case '1':
          telefone = this.selectedEvent.paciente.contato.telefonePrincipal;
          break;
        case '2':
          telefone = this.selectedEvent.paciente.contato.telefoneSecundario;
          break;
        case '3':
          telefone = this.outroTelefoneParaWhatsApp;
          break;
        default:
          alert("Escolha um telefone válido");
          return;
      }

      telefone = telefone.replace(/[^0-9]+/g, '');

      var link = await this.buscarLink();

      var mensagem = `Olá ${this.selectedEvent.name}, seu agendamento com o médico está previsto para ${this.selectedEvent.dataAgendamento} às ${this.selectedEvent.horaAgendamento}. Por favor, entre no link abaixo para confirmar sua consulta: ${link}`;

      var mensagemCodificada = encodeURIComponent(mensagem);


      window.open(`https://api.whatsapp.com/send?phone=${'55' + telefone}&text=${mensagemCodificada}`, 'whatsappweb');
    },
    async buscarLink(): Promise<string> {
      let linkParaConfirmarAgendamento = "";

      await this.agendamentoService.gerarLinkParaConfirmarAgendamento(this.selectedEvent.id)
        .then(
          (res: { data: { link: string; }; }) => {
            linkParaConfirmarAgendamento = res.data.link;
          },
          (err: any) => {
            AlertaSimplesErro('Aviso:', err);
          }
        );

      return linkParaConfirmarAgendamento;
    },
    obterCorSituacaoAgendamento(situacao: string) {
      switch (situacao) {
        case 'Marcado':
          return '#4299E1';
        case 'EmEspera':
          return '#38B2AC';
        case 'EmAndamento':
          return '#ED8936';
        case 'Faltou':
          return '#F56565';
        case 'Finalizado':
          return '#A0AEC0';
        default:
          return '#ECC94B';
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.altura {
  height: calc(100vh - 170px);
}

.v-current-time {
  height: 2px;
  background-color: #ea4335;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;

  &.first::before {
    content: "";
    position: absolute;
    background-color: #ea4335;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-top: -5px;
    margin-left: -6.5px;
  }
}

.v-calendar-daily__day-interval {
  background-color: var(--cor-de-fundo) !important;
}

.menu-card {
  background-color: #f5f5f5 !important;
  border-radius: 8px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  min-width: 180px;
  max-width: 520px;
}

p {
  margin-bottom: 8px;
  font-size: 13px;
}
</style>