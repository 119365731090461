import http from './ApiConfig';
import { Service } from './Service';

export class HorarioAgendaService extends Service {
    constructor() {
        super('horarioAgenda');
    }

    public BuscarHorariosPorAgenda(agendaId: number) {
        return http.get(`${this._nomeControle}/${agendaId}`, this.GetHeader());
    }
}