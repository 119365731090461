<template>
  <div>
    <v-row>
      <v-col cols="12" md="12" sm="12">
        <v-data-table :loading="carregando ? 'success' : null" no-data-text="Nenhum(a) Feriado encontrado(a)!"
          loading-text="Carregando os itens desta tabela! Aguarde..." :headers="colunasFeriados"
          :header-props="headerProps" :items="listaFeriados" :items-per-page="-1" hide-default-footer
          class="elevation-0">
          <template v-slot:top>
            <v-row>
              <v-col class="botao-novo" cols="12" sm="12" md="12">
                <v-btn depressed color="success" class="mb-2" @click="abrirModalFeriados()">
                  <v-icon class="mx-2">mdi-plus</v-icon>
                  Novo
                </v-btn>
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn class="mx-2" x-small fab color="success" @click="abrirModalFeriadosParaEdicao(item)"
              title="Editar Feriado">
              <v-icon class="mx-2" small>mdi-pencil</v-icon>
            </v-btn>
            <v-btn class="mx-2" x-small fab color="error" @click="excluirFeriado(item)" title="Excluir Feriado">
              <v-icon class="mx-2" small>mdi-minus</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog transition="dialog-bottom-transition" v-model="modalFeriados" max-width="700px">
      <v-card class="mx-auto">
        <v-card-title>
          <v-icon left color="success"> {{ iconeTituloFeriados }}</v-icon> {{ tituloFeriados }}
        </v-card-title>
        <validation-observer ref="observerFeriados" v-slot="{}">
          <v-form>
            <v-card class="elevation-0">
              <v-card-text class="pa-5">
                <v-row>
                  <v-col cols="12" md="12" sm="12">
                    <soi-input type="date" :name="'Data'" v-model="feriadoAgenda.data" :label="'Data'"
                      :rules="{ required: true }"></soi-input>
                  </v-col>
                  <v-col cols="12" md="12" sm="12">
                    <soi-input :name="'Descrição'" v-model="feriadoAgenda.descricao" :label="'Descrição'"
                      :rules="{ required: true }"></soi-input>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="warning" @click="fecharModal()" :loading="carregando">
                  <v-icon left>mdi-keyboard-return</v-icon>
                  Voltar
                </v-btn>
                <v-btn @click="salvar()" color="success" :loading="carregando" :disabled="carregando">
                  <v-icon left>mdi-checkbox-marked-circle</v-icon>
                  Salvar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </validation-observer>
      </v-card>
    </v-dialog>
  </div>
</template>
<script lang="ts">
import Vue from 'vue'
import DateHelper from "../../helpers/DateHelper";
import { AlertaExclusaoQuestaoBooleano } from "../../helpers/MensagemHelper";
import FeriadoAgenda from "../../models/agenda/FeriadoAgenda";

export default Vue.extend({
  name: "soi-feriadoAgenda",
  props: {
    value: {
      type: Array as () => FeriadoAgenda[],
      required: true,
    },
  },
  data() {
    return {
      carregando: false,
      modalFeriados: false,
      editarFeriados: false,
      feriadoAgenda: new FeriadoAgenda(),
      listaFeriados: this.value,
      feriadoAgendaEdicaoIndex: -1,
      headerProps: {
        sortByText: "Ordenar por"
      },
      colunasFeriados: [
        {
          text: "Data",
          align: "center",
          value: "data",
          filterable: true,
          sortable: true,
        },
        {
          text: "Descriçao",
          align: "center",
          value: "descricao",
          filterable: true,
        },
        {
          text: "Ações",
          align: "center",
          value: "actions",
        },
      ],
    };
  },
  computed: {
    tituloFeriados() {
      return this.editarFeriados ? "Editar Feriados" : "Criar Feriados";
    },
    iconeTituloFeriados() {
      return this.editarHorarios ? "mdi-clock-edit" : "mdi-clock-plus";
    },
  },
  watch: {
    value() {
      this.listaFeriados = this.value;
    },
  },
  methods: {
    abrirModalFeriados() {
      this.modalFeriados = true;
      this.feriadoAgenda = new FeriadoAgenda();
    },
    async salvar() {
      const valid = await (this.$refs.observerFeriados as any).validate();
      if (!valid) {
        return;
      }
      this.feriadoAgenda.data = DateHelper.formataParaDiaMesAno(
        this.feriadoAgenda.data
      );

      if (this.editarFeriados) {
        if (this.feriadoAgendaEdicaoIndex != -1)
          Object.assign(
            this.listaFeriados[this.feriadoAgendaEdicaoIndex],
            this.feriadoAgenda
          );
      } else {
        this.listaFeriados.push(this.feriadoAgenda);
      }

      this.$emit("input", this.listaFeriados);
      this.modalFeriados = false;
      this.editarFeriados = false;
      this.feriadoAgenda = new FeriadoAgenda();
      await (this.$refs.observerFeriados as any).reset();
    },
    async abrirModalFeriadosParaEdicao(feriado: FeriadoAgenda) {
      this.feriadoAgendaEdicaoIndex = this.listaFeriados.indexOf(feriado);
      this.editarFeriados = true;
      this.modalFeriados = true;
      setTimeout(() => {
        this.feriadoAgenda = Object.assign({}, feriado);
        this.feriadoAgenda.data = DateHelper.formatarParaAnosMesDiaSubstring(
          feriado.data
        );
      }, 10);
    },
    async excluirFeriado(feriado: FeriadoAgenda) {
      if (await AlertaExclusaoQuestaoBooleano()) {
        const index = this.listaFeriados.indexOf(feriado);
        this.listaFeriados.splice(index, 1);
        this.$emit("input", this.listaFeriados);
      }
    },
    async fecharModal() {
      this.modalFeriados = false;
      this.editarFeriados = false;
      await (this.$refs.observerFeriados as any).reset();
      this.feriadoAgenda = new FeriadoAgenda();
    },
  },
});
</script>
