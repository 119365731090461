<template>
  <v-card outlined class="mt-2 mb-2">
    <v-card-title> Planos </v-card-title>
    <v-card-text>
      <v-layout>
        <v-text-field class="mr-2" outlined dense v-model="valorFiltro" append-icon="mdi-magnify"
          label="Digite o que deseja pesquisar...">
        </v-text-field>
        <v-btn color="success" dark @click="abrirModal" v-if="!visualizar">
          <v-icon>mdi-plus</v-icon>
          Novo
        </v-btn>
      </v-layout>
      <v-layout>
        <v-dialog v-model="dialog" max-width="600px">
          <v-card class="mx-auto">
            <v-card-title>
              <h5>{{ titulo }}</h5>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <soi-input outlined :name="'Nome'" :label="'Nome *'" v-model="planoEditado.nome"
                      :placeholder="'Nome do Plano'" :disabled="visualizar">
                    </soi-input>
                    <v-textarea color="success" outlined :name="'Descricao'" :label="'Descrição'"
                      v-model="planoEditado.descricao" :placeholder="'Descrição do Plano'" :disabled="visualizar">
                    </v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" @click="dialog = false">
                <v-icon></v-icon>
                <v-icon left>mdi-minus-circle</v-icon> Cancelar
              </v-btn>
              <v-btn v-if="!visualizar" color="success" @click="salvar()">
                <v-icon left>mdi-checkbox-marked-circle</v-icon> Salvar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
      <v-card>
        <v-card-text>
          <v-data-table :headers="colunas" no-data-text="Nenhum plano encontrado!" :items-per-page="itens"
            :items="lista" :page.sync="paginaAtual" :search="valorFiltro" :footer-props="footerProps"
            :header-props="headerProps">
            <template v-slot:item.actions="{ item }">
              <v-btn class="mx-2" x-small fab color="success" title="Editar Plano" @click="editar(item)"
                v-if="!visualizar">
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
              <v-btn class="mx-2" x-small fab color="error" @click="excluir(item.id)" title="Excluir Plano"
                v-if="!visualizar">
                <v-icon small>mdi-minus</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import Plano from '../../models/convenio/Plano';
import Vue from "vue";
import { AlertaExclusaoQuestaoBooleano, AlertaSimplesErro, MensagemMixin } from '../../helpers/MensagemHelper';

export default Vue.component('lista-plano', {
  props: {
    lista: Array,
  },
  data() {
    return {
      dialog: false,
      plano: new Plano(),
      indexEditado: -1,
      planoEditado: {
        nome: "",
        descricao: ""
      },
      visualizar: this.$route.name == 'conveniosFormView' ? true : false,
      valorFiltro: "",
      totalLinhas: 0,
      paginaAtual: 1,
      itens: 3,
      headerProps: {
        sortByText: "Ordenar por"
      },
      colunas: [
        {
          text: 'Nome',
          align: 'start',
          value: 'nome',
          filterable: true,
          sortable: true,
        },
        {
          text: "Descrição",
          value: "descricao",
        },
        {
          text: "Ações",
          value: "actions",
        },

      ],
      footerProps: {
        itemsPerPageOptions: [3, 5, 10, 15],
        itemsPerPageText: 'Planos por página',
        pageText: '{0}-{1} de {2}'
      }
    }
  },
  computed: {
    titulo() {
      return this.indexEditado === -1 ? 'Novo Plano' : 'Editar Plano';
    }
  },
  watch: {
    lista: function () {
      this.totalLinhas = this.lista.length;
    }
  },
  mounted() {
    this.totalLinhas = this.lista.length;
  },
  methods: {
    onFiltered(filteredItems: any) {
      this.totalLinhas = filteredItems.length
    },

    abrirModal() {
      this.indexEditado = -1
      this.planoEditado = {
        nome: '',
        descricao: '',
      }
      this.dialog = true
    },

    editar(item) {
      this.indexEditado = this.lista.indexOf(item);
      this.planoEditado = Object.assign({}, item);
      this.dialog = true;
    },

    fecharModal() {
      this.dialog = false
      this.planoEditado = {
        nome: '',
        descricao: '',
      }
      this.indexEditado = -1
    },

    salvar() {
      if (this.planoEditado && this.planoEditado.nome !== "") {
        
        const planoExistente = this.lista.some(item => item.nome.toLowerCase() === this.planoEditado.nome.toLowerCase());
        if (planoExistente) {
          AlertaSimplesErro("Aviso:", "Já existe um plano cadastrado com esse nome no convênio.");
          return;
        }
        if (this.indexEditado > -1) {
          Object.assign(this.lista[this.indexEditado], this.planoEditado)
        } else {
            this.lista.push(this.planoEditado);
        }

        this.$emit('input', this.lista);
        this.fecharModal();
      } else {
        MensagemMixin(3000, 'error', 'O campo Nome do plano deve ser preenchido!');
      }
    },

    async excluir(plano: Plano) {
      if (await AlertaExclusaoQuestaoBooleano()) {
        const index = this.lista.indexOf(plano);
        this.lista.splice(index, 1);
        this.$emit('input', this.lista);
      }
    },
  }
})
</script>

<style scoped></style>