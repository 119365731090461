import Contato from "../objetosDeValor/Contato";
import Endereco from "../objetosDeValor/Endereco";
import Plano from "./Plano";

export default class Convenio{
    public id: number;
    public nome: string;
    public periodoRetornoDias: number;
    public endereco: Endereco;
    public contato: Contato;
    public foto: string;
    public file?: File;
    public listaPlanos: string;
    public planos: [];
    
    constructor(){
        this.id = 0;
        this.nome = '';
        this.periodoRetornoDias = 0;
        this.endereco = new Endereco();
        this.contato = new Contato();
        this.foto = '';
        this.listaPlanos = '';     
        this.planos = [];
    }
}
