<template>
    <v-app>
        <soi-header :titulo="titulo" />
        <soi-sidebar @miniSidebar="atualizaSidebar" />
        <v-main>
            <v-card :class="$route.name !== 'home' ? 'main-card' : ''">
                <slot />
            </v-card>
        </v-main>
        <v-footer :class="miniSidebar ? 'v-footer-mini' : 'v-footer-full'">
            <div class="links">
                <ul>
                    <li><a href="#">Suporte</a></li>
                    <li><a href="#">Ouvidoria</a></li>
                    <li><a href="#">Política de Privacidade</a></li>
                    <li><a href="#">Termos de Uso</a></li>
                </ul>
            </div>
            <div class="copyright">
                <span>&copy; {{ anoCorrente + ' ' + empresa }}</span>
            </div>
            <slot name="assistencia"></slot>
        </v-footer>
    </v-app>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
    name: "soi-masterpage",
    props: {
        titulo: {
            type: String,
            required: true,
        }
    },
    watch: {
        year() {
            this.anoCorrente = new Date().getFullYear();
        }
    },
    data() {
        return {
            anoCorrente: new Date().getFullYear(),
            empresa: 'Grupo SOITIC',
            miniSidebar: false,
        }
    },
    methods: {
        atualizaSidebar(estado) {
            this.miniSidebar = estado;
        }
    },
});
</script>

<style>
.main-card {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 1.5rem;
}

.v-footer {
    background-color: transparent !important;
    padding: 0.5rem;
    gap: 1rem;
    font-weight: 500;
    font-size: 12px;
    line-height: 1rem;
    display: flex;
    justify-content: space-between;
    margin: 1.875rem 1.875rem 1.875rem;
}

.v-footer-mini {
    margin-left: 114px;
}

.v-footer-full {
    margin-left: 305px;
}

.v-footer .links {
    padding-left: 1.875rem;
}

.v-footer .links ul {
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0;
}

.v-footer .links ul li a {
    text-decoration: none;
    margin: 0 0.5rem;
}

.v-footer .copyright {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
}

.v-footer .copyright span {
    color: #009A3C;
}
</style>