export default class Triagem {
    public id: number;
    public statusTriagem: boolean;
    public pacienteId: number;
    public agendamentoId: number;
    public grauDor: number;
    public peso: number;
    public altura: number;
    public frequenciaCardiaca: number;
    public frequenciaRespiratoria: number;
    public pressaoArterial: number;
    public temperatura: number;
    public doencaCronica: string;
    public sintomaPrincipal: string;
    public dataSintomaPrincipal: string;
    public opcaoCondicaoPreExistente: OpcaoResposta;
    public atividadeFisica: OpcaoResposta; 
    public boaAlimentacao: OpcaoResposta; 
    public opcaoAlgumaMedicacao: OpcaoResposta;
    public algumaMedicacao: string;
    public historicoFamiliar: OpcaoResposta;
    public opcaoHistoricoAlergia: OpcaoResposta;
    public historicoAlergia: string;

    constructor() {
        this.id = 0;
        this.statusTriagem = false;
        this.pacienteId = 0;
        this.agendamentoId = 0;
        this.grauDor = 0;
        this.peso = 0;
        this.altura = 0;
        this.frequenciaCardiaca = 0;
        this.frequenciaRespiratoria = 0;
        this.pressaoArterial = 0;
        this.temperatura = 0;
        this.opcaoCondicaoPreExistente = OpcaoResposta.NaoRespondido; 
        this.doencaCronica = '';
        this.sintomaPrincipal = '';
        this.dataSintomaPrincipal = '';
        this.atividadeFisica = OpcaoResposta.NaoRespondido; 
        this.boaAlimentacao = OpcaoResposta.NaoRespondido; 
        this.opcaoAlgumaMedicacao = OpcaoResposta.NaoRespondido; 
        this.algumaMedicacao = '';
        this.historicoFamiliar = OpcaoResposta.NaoRespondido; 
        this.opcaoHistoricoAlergia = OpcaoResposta.NaoRespondido; 
        this.historicoAlergia = '';
    }
}

export enum OpcaoResposta {
    NaoRespondido = 0,
    Sim = 1,
    Nao = 2
}
