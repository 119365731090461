<template>
    <soi-masterpage titulo="Triagem">
        <div :loading="carregando ? 'success' : null" :disabled="carregando">
            <v-card-title>{{ subTitulo }}</v-card-title>
            <soi-dados-padrao-triagem v-model="triagem" :mostrarBotoes="false" :paciente="paciente" :disabled="visualizarTriagemListagem"></soi-dados-padrao-triagem>
        </div>
    </soi-masterpage>
</template>
<script lang="ts">
import Agendamento from "../../models/agendamento/Agendamento";
import Pacientes from "../../models/pacientes/Pacientes";
import Triagem from "../../models/triagem/Triagem";
import Vue from "vue";

export default Vue.extend({

    data() {
        return {
            carregando: false,
            triagem: new Triagem(),
            paciente: new Pacientes(),
            idPaciente: parseInt(this.$route.params.pacienteId),


        }
    },
    computed: {
        subTitulo() {
            if (this.visualizar || this.visualizarTriagemListagem) {
                return "Visualizar Triagem";
            } else if (this.idPaciente) {
                return "Triagem do Paciente";
            }
        },

        visualizarTriagemListagem() {
            return this.$route.name === "triagensFormView";
        },
    },
})
</script>