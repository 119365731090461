import http from './ApiConfig';
import { Service } from './Service';

export class FeriadoAgendaService extends Service {

    constructor() {
        super('feriado');
    }

    public ObterTodosOsFeriados() {
        return http.get(`${this._nomeControle}`, this.GetHeader());
    }
}