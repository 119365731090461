import { Service } from './Service';
import http from './ApiConfig';

export class BloqueioHorarioAgendaService extends Service {

    constructor() {
        super('bloqueioHorario');
    }

    public ObterTodosOsBoqueios() {
        return http.get(`${this._nomeControle}`, this.GetHeader());
    }
}