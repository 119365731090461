<template>
    <v-dialog v-model="modalAdicionarModelo" width="800" height="600">
        <v-card class="mx-auto">
            <v-toolbar flat dark color="success">
                <v-toolbar-title>
                    <v-icon>mdi mdi-content-save-check</v-icon>
                    Adicionar Modelo
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon dark @click="fecharModal">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text class="mt-4">
                <v-row>
                    <v-col cols="12" xs="12" md="4">
                        <v-text-field
                            v-model="modelo.tipoModelo"
                            label="Tipo do modelo"
                            outlined
                            dense
                            :disabled="true"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" xs="12" md="8">
                        <soi-input
                            v-model="modelo.nome"
                            append-icon="mdi-magnify"
                            label="Titulo do modelo"
                        >
                        </soi-input>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="justify-end">
                <v-btn color="error" @click="fecharModal">
                    <v-icon>mdi-cancel</v-icon>Cancelar</v-btn
                >
                <v-btn
                    color="success"
                    class="mr-2"
                    :disabled="this.modelo.nome.length === 0 || this.carregando"
                    @click="salvar" :loading="carregando" 
                >
                    <v-icon>mdi mdi-content-save-check</v-icon> Salvar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script lang="ts">
import { ModelosService } from '../../services/ModelosService';
import { MensagemMixin } from '../../helpers/MensagemHelper';
import Modelos from '../../models/modelos/Modelos';

export default {
    name: 'soi-adicionar-modelo',
    props: {
        tipoModeloAtivo: {
            type: String,
            required: true,
        },
        descricaoModelo: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            modalAdicionarModelo: false,      
            carregando: false,      
            modelo: new Modelos(),
            service: new ModelosService(),
        };
    },        
    mounted() {
        this.modelo.tipoModelo = this.tipoModeloAtivo;
    },
    methods: {
        abrirModalAdicionarModelo() {
            this.modalAdicionarModelo = true;   
            this.modelo.nome = '';                    
        },

        fecharModal() {            
            this.modalAdicionarModelo = false;                        
        },

        async salvar() {
            try {
                this.carregando = true;
                this.modelo.descricao = this.descricaoModelo;
                
                await this.service.Post(this.modelo).then(
                    (res) => {
                        MensagemMixin(
                            3000,
                            'success',
                            'Modelo cadastrado com sucesso.'
                        );
                        this.responseOk = true;
                        this.modalAdicionarModelo = false;                        
                        this.$emit('modeloCadastrado');
                    },
                    (err) => {
                        MensagemMixin(3000, 'error', err);
                        this.responseOk = false;
                    }
                );
            } catch (error) {
                MensagemMixin(3000, 'error', error);
            }
            finally {
                this.carregando = false;
            }
        },
    },
};
</script>
