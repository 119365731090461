import http from './ApiConfig';
import { Service } from './Service';

export class AgendaService extends Service {
    constructor() {
        super('agenda');
    }

    public ObterTodosOsHorariosReservadosDesdeHoje() {
        return http.get(`${this._nomeControle}/obter-horarios-reservados`, this.GetHeader());
    }
}