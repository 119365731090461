import  http  from './ApiConfig';
import { Service } from './Service';

export class UsuarioService extends Service{

    constructor() {
        super('usuarios');
    }

    public GetAllPaginado(nomeOuId: string, limit: number, page: number) {
        return http.get(`${this._nomeControle}?nomeOuId=${nomeOuId}&limit=${limit}&page=${page}`, this.GetHeader());
    }
    
    public MudarTema(){
        return http.put(`${this.GetNomeControle()}/MudarTema`, this.GetHeader());
    }

    public Delete(id: number) {
        return http.delete(`usuario/usuarioId`, this.GetHeader());
    }

    public ListarUsuariosParaLog() {
        return http.get(`${this._nomeControle}/listar-usuarios`, this.GetHeader());
    }
    
    public AtivarOuInativarStatus(id:string, ativo:boolean) {
        return http.patch(
            `${this._nomeControle}/${id}/ativar-ou-inativar-status`,
            ativo,
            {
              headers: {
                'Content-Type': 'application/json',
              },
            }
          );
    }
}