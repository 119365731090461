import http from './ApiConfig';
import { Service } from './Service';

export class NotaDiaService extends Service {

    constructor() {
        super('notaDia');
    }

    public async GetNotaDoDia(agendaId: number, data: string) {
        return http.get(`${this._nomeControle}/get-nota-do-dia?agendaId=${agendaId}&data=${data}`, this.GetHeader());
    }
}